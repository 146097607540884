table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: #f2f2f2;
  }
  
  table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  table tr:nth-child(even){background-color: #f2f2f2;}
  table tr:nth-child(odd){background-color: #ffffff;}
  
  table tr:hover {background-color: #ddd;}
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(58, 44, 134);
    color: white;
  }

  .rsvpTableThead > tr:first-child {
    display: none;
  }